<template>
  <div class="order">
    <div class="productItem"
      v-for="(item,index) in orderList"
      :key="index">
      <img :src="item.product && item.product.image && item.product.image.thumbnailStaticUrl || require('@/assets/productDefault.png')">
      <div class="right">
        <div class="titleBox">
          {{item.product && item.product.title1 || '未命名'}}
        </div>
        <div class="guid">
          {{item.memo}}
        </div>
        <div class="optionBox">
          <div>￥ <span>{{item.totalPrice}}</span></div>
        </div>
        <!-- <van-icon name="checked" /> -->
      </div>
    </div>
    <infinite-loading @infinite="getOrderList"
      :identifier="infiniteId"
      direction="bottom">
      <div slot="spinner">读取中...</div>
      <div slot="no-more"
        class="no-more">已加载全部</div>
      <div slot="no-results"
        class="no-results">
        <img :src="require('@/assets/kong.png')">
      </div>
    </infinite-loading>
  </div>
</template>

<script>
export default {
  name: 'order',
  data() {
    return {
      orderList: [],
      listQuery: {
        limit: 20,
        offset: 0
      },
      listLoadIng: false,
      infiniteId: +new Date()
    }
  },
  methods: {
    getOrderList($state) {
      if (this.listLoadIng) {
        return
      } else {
        this.productLoading = true
        this.$http
          .get(
            `https://${localStorage.getItem(
              'url'
            )}/be/api/restaurant/guest/desk/details`,
            {
              params: {
                ...this.listQuery,
                deskId: localStorage.getItem('id')
              }
            }
          )
          .then(res => {
            if (res.data.data.details.length) {
              this.listQuery.offset += 20
              this.orderList.push(...res.data.data.details)
              this.productLoading = false
              $state.loaded()
            } else {
              $state.complete()
              this.isLoading = false
            }
          })
          .catch(err => {})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.order {
  height: calc(100% - 6rem);
  box-sizing: border-box;
  overflow: auto;
}
.productItem {
  height: 12rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid rgb(236, 236, 236);
  display: flex;
  align-items: center;
  img {
    background: #f5f5f5;
    margin-right: 1rem;
    width: 10rem;
    height: 10rem;
    object-fit: contain;
  }
  .right {
    height: 100%;
    flex: 1;
    position: relative;
    .titleBox {
      text-align: left;
      font-weight: 600;
      height: 3rem;
      line-height: 1.5rem;
      font-size: 1.6rem;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .guid {
      line-height: 2rem;
      font-size: 1.6rem;
      color: #999;
      text-align: left;
      margin-top: 1rem;
    }
    .optionBox {
      height: 3rem;
      margin-top: 1rem;
      display: flex;
      align-items: flex-end;
      div {
        font-size: 1.4rem;
        color: #333;
        span {
          font-size: 2rem;
          color: #ee0a24;
        }
      }
    }
    .van-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 6rem;
      color: green;
    }
  }
}
</style>

